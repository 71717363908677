<!--Add Hero Cover-->
<app-hero-cover></app-hero-cover>
<div class="toolbar" role="banner" id="main">
  <!--Side Nav button-->
  <span id="menu-btn" style="font-size: 30px; cursor: pointer" (click)="openNav()">&#9776;</span>
  <a href="/">
    <img width="40" alt="Austin Smyth" src="../assets/austin.jpg" />
  </a>
  <span>Welcome</span>
  <div class="spacer"></div>
  <a aria-label="Austin on instagram" target="_blank" rel="noopener" href="https://www.instagram.com/ausmyth/"
    title="Instagram">
    <svg id="instagram-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <rect width="400" height="400" fill="none" />
      <path
        d="M11.984 16.815c2.596 0 4.706-2.111 4.706-4.707 0-1.409-.623-2.674-1.606-3.538-.346-.303-.735-.556-1.158-.748-.593-.27-1.249-.421-1.941-.421s-1.349.151-1.941.421c-.424.194-.814.447-1.158.749-.985.864-1.608 2.129-1.608 3.538 0 2.595 2.112 4.706 4.706 4.706zm.016-8.184c1.921 0 3.479 1.557 3.479 3.478 0 1.921-1.558 3.479-3.479 3.479s-3.479-1.557-3.479-3.479c0-1.921 1.558-3.478 3.479-3.478zm5.223.369h6.777v10.278c0 2.608-2.114 4.722-4.722 4.722h-14.493c-2.608 0-4.785-2.114-4.785-4.722v-10.278h6.747c-.544.913-.872 1.969-.872 3.109 0 3.374 2.735 6.109 6.109 6.109s6.109-2.735 6.109-6.109c.001-1.14-.327-2.196-.87-3.109zm2.055-9h-12.278v5h-1v-5h-1v5h-1v-4.923c-.346.057-.682.143-1 .27v4.653h-1v-4.102c-1.202.857-2 2.246-2 3.824v3.278h7.473c1.167-1.282 2.798-2 4.511-2 1.722 0 3.351.725 4.511 2h7.505v-3.278c0-2.608-2.114-4.722-4.722-4.722zm2.722 5.265c0 .406-.333.735-.745.735h-2.511c-.411 0-.744-.329-.744-.735v-2.53c0-.406.333-.735.744-.735h2.511c.412 0 .745.329.745.735v2.53z"
        fill="#fff" />
    </svg>
  </a>
  <a aria-label="Austin on twitter" target="_blank" rel="noopener" href="https://twitter.com/AustinSmyth"
    title="Twitter">
    <svg id="twitter-logo" height="24" data-name="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
      <rect width="400" height="400" fill="none" />
      <path
        d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23"
        fill="#fff" />
    </svg>
  </a>
</div>
<div id="mySidenav" class="sidenav">
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
  <a routerLink="/about">About</a>
  <a routerLink="/players">Player Explorer</a>
  <a routerLink="/pictures">Gallery</a>
  <a routerLink="/contact">Contact</a>
</div>
<div class="content" role="main">
  <router-outlet id="main-content"></router-outlet>
</div>

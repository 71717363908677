import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { PictureComponent } from './picture/picture.component';
import { ArticleListComponent } from './article-list/article-list.component';
import { HeroCoverComponent } from './hero-cover/hero-cover.component';
import { AppRoutingModule } from './app-routing.module';
import { PlayerExplorerComponent } from './player-explorer/player-explorer.component';
import { ChartsModule } from 'ng2-charts';
import { AboutDetailsComponent } from './about-details/about-details.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';

@NgModule({
  declarations: [
    AppComponent,
    PictureComponent,
    ArticleListComponent,
    HeroCoverComponent,
    PlayerExplorerComponent,
    AboutDetailsComponent,
    ContactDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

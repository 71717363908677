import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleListComponent } from './article-list/article-list.component';
import {PlayerExplorerComponent} from './player-explorer/player-explorer.component';
import {AboutDetailsComponent} from './about-details/about-details.component';
import {ContactDetailsComponent} from './contact-details/contact-details.component';

const routes: Routes = [
  { path: 'pictures', component: ArticleListComponent },
  { path: 'players', component: PlayerExplorerComponent },
  { path: 'about', component: AboutDetailsComponent },
  { path: 'contact', component: ContactDetailsComponent },
  { path: '', component: AboutDetailsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

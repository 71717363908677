export const playerNames = [
  'Shkodran Mustafi',
  'Héctor Bellerín',
  'Sead Kolasinac',
  'Ainsley Maitland-Niles',
  'Sokratis Papastathopoulos',
  'Nacho Monreal',
  'Laurent Koscielny',
  'Konstantinos Mavropanos',
  'Carl Jenkinson',
  'Rob Holding',
  'Pierre-Emerick Aubameyang',
  'Alexandre Lacazette',
  'Edward Nketiah',
  'Bernd Leno',
  'Mesut Özil',
  'Henrikh Mkhitaryan',
  'Granit Xhaka',
  'Lucas Torreira',
  'Mohamed Elneny',
  'Matteo Guendouzi',
  'David Luiz Moreira Marinho',
  'Emiliano Martínez',
  'Calum Chambers',
  'Daniel Ceballos Fernández',
  'Cédric Soares',
  'Nicolas Pépé',
  'Reiss Nelson',
  'Joseph Willock',
  'Gabriel Teodoro Martinelli Silva',
  'Kieran Tierney',
  'Bukayo Saka',
  'Emile Smith Rowe',
  'Tyreece John-Jules',
  'Pablo Marí',
  'Zech Medley',
  'Matt Macey',
  'Matthew Smith',
  'Ahmed El Mohamady',
  'James Chester',
  'Neil Taylor',
  'Kortney Hause',
  'Jonathan Kodjia',
  'Ørjan Nyland',
  'Conor Hourihane',
  'Jack Grealish',
  'Anwar El Ghazi',
  'John McGinn',
  'Andre Green',
  'Birkir Bjarnason',
  'Keinan Davis',
  'Henri Lansbury',
  'José Ignacio Peleteiro Romallo',
  'Tom Heaton',
  'Daniel Drinkwater',
  'Matt Targett',
  'Wesley Moraes',
  'Jed Steer',
  'Tyrone Mings',
  'Ezri Konsa Ngoyo',
  'Bjorn Engels',
  'Frédéric Guilbert',
  'Mahmoud Ahmed Ibrahim Hassan',
  'Douglas Luiz Soares de Paulo',
  'Lovre Kalinic',
  'Marvelous Nakamba',
  'Indiana Vassilev',
  'José Reina',
  'Mbwana Samatta',
  'Borja González Tomás',
  'Kaine Hayden',
  'Steve Cook',
  'Nathan Aké',
  'Charlie Daniels',
  'Chris Mepham',
  'Simon Francis',
  'Adam Smith',
  'Lloyd Kelly',
  'Diego Rico',
  'Jack Simpson',
  'Callum Wilson',
  'Joshua King',
  'Dominic Solanke',
  'Sam Surridge',
  'Asmir Begovic',
  'Artur Boruc',
  'Mark Travers',
  'Ryan Fraser',
  'David Brooks',
  'Junior Stanislas',
  'Jordon Ibe',
  'Lewis Cook',
  'Andrew Surman',
  'Dan Gosling',
  'Jefferson Lerma',
  'Jack Stacey',
  'Philip Billing',
  'Arnaut Danjuma Groeneveld',
  'Aaron Ramsdale',
  'Harry Wilson',
  'Christian Saydee',
  'Gavin Kilkenny',
  'Mihai-Alexandru Dobre',
  'Shane Duffy',
  'Martín Montoya',
  'Bernardo Fernandes da Silva Junior',
  'Leon Balogun',
  'Gaëtan Bong',
  'Lewis Dunk',
  'Matthew Clarke',
  'Glenn Murray',
  'Jürgen Locadia',
  'Florin Andone',
  'Mathew Ryan',
  'David Button',
  'Pascal Groß',
  'Alireza Jahanbakhsh',
  'José Heriberto Izquierdo Mena',
  'Anthony Knockaert',
  'Yves Bissouma',
  'Davy Pröpper',
  'Solomon March',
  'Beram Kayal',
  'Dale Stephens',
  'Leandro Trossard',
  'Dan Burn',
  'Adam Webster',
  'Neal Maupay',
  'Aaron Mooy',
  'Aaron Connolly',
  'Tudor Baluta',
  'Steven Alzate',
  'Ezequiel Schelotto',
  'Tariq Lamptey',
  'Alexis Mac Allister',
  'Alex Cochrane',
  'Ben Mee',
  'James Tarkowski',
  'Matthew Lowton',
  'Phil Bardsley',
  'Charlie Taylor',
  'Kevin Long',
  'Ben Gibson',
  'Ashley Barnes',
  'Chris Wood',
  'Matej Vydra',
  'Nick Pope',
  'Joe Hart',
  'Johann Berg Gudmundsson',
  'Dwight McNeil',
  'Robbie Brady',
  'Steven Defour',
  'Jeff Hendrick',
  'Ashley Westwood',
  'Jack Cork',
  'Aaron Lennon',
  'Erik Pieters',
  'Jay Rodriguez',
  'Bailey Peacock-Farrell',
  'Mace Goodridge',
  'Anthony Driscoll-Glennon',
  'Abd-Al-Ali Morakinyo Olaposi Koiki',
  'Josh Brownhill',
  'Jimmy Dunne',
  'Bobby Thomas',
  'Max Thompson',
  'Lukas Jensen',
  'Josh Benson',
  'Joel Mumbongo',
  'Marcos Alonso',
  'Antonio Rüdiger',
  'César Azpilicueta',
  'Emerson Palmieri dos Santos',
  'Andreas Christensen',
  'Davide Zappacosta',
  'Olivier Giroud',
  'Kepa Arrizabalaga',
  'Willy Caballero',
  'Willian Borges Da Silva',
  'Pedro Rodríguez Ledesma',
  'Ruben Loftus-Cheek',
  'Callum Hudson-Odoi',
  'Ross Barkley',
  'Jorge Luiz Frello Filho',
  "N'Golo Kanté",
  'Ethan Ampadu',
  'Christian Pulisic',
  'Mateo Kovacic',
  'Kurt Zouma',
  'Tammy Abraham',
  'Michy Batshuayi',
  'Robert Kenedy Nunes do Nascimento',
  'Mason Mount',
  'Reece James',
  'Fikayo Tomori',
  'Billy Gilmour',
  'Addji Keaninkin Marc-Israel Guehi',
  'Faustino Anjorin',
  'Armando Broja',
  'Lewis Bate',
  'Patrick van Aanholt',
  'James Tomkins',
  'Mamadou Sakho',
  'Joel Ward',
  'Scott Dann',
  'Martin Kelly',
  'Christian Benteke',
  'Connor Wickham',
  'Vicente Guaita',
  'Wayne Hennessey',
  'Wilfried Zaha',
  'Luka Milivojevic',
  'Andros Townsend',
  'Max Meyer',
  'Jeffrey Schlupp',
  'James McArthur',
  'Cheikhou Kouyaté',
  'Luke Dreher',
  'James McCarthy',
  'Jordan Ayew',
  'Gary Cahill',
  'Víctor Camarasa',
  'Jairo Riedewald',
  'Stephen Henderson',
  'Sam Woods',
  'Tyrick Mitchell',
  'Brandon Pierrick',
  'James Daly',
  'Nya Kirby',
  'Giovanni McGregor',
  'Nikola Tavares',
  'John-Kymani Gordon',
  'Alex Iwobi',
  'Lucas Digne',
  'Yerry Mina',
  'Michael Keane',
  'Seamus Coleman',
  'Leighton Baines',
  'Cenk Tosun',
  'Dominic Calvert-Lewin',
  'Jordan Pickford',
  'Maarten Stekelenburg',
  'Richarlison de Andrade',
  'Gylfi Sigurdsson',
  'Bernard Anício Caldeira Duarte',
  'Theo Walcott',
  'Tom Davies',
  'Ademola Lookman',
  'Idrissa Gueye',
  'Morgan Schneiderlin',
  'Fabian Delph',
  'André Filipe Tavares Gomes',
  'Lewis Gibson',
  'Mason Holgate',
  'Jonas Lössl',
  'Moise Kean',
  'Jean-Philippe Gbamin',
  'Djibril Sidibé',
  'Beni Baningime',
  'Anthony Gordon',
  'Oumar Niasse',
  'Rhu-endly Martina',
  'João Manuel Neves Virgínia',
  'Jarrad Branthwaite',
  'Ellis Simms',
  'Ricardo Domingos Barbosa Pereira',
  'Benjamin Chilwell',
  'Jonny Evans',
  'Christian Fuchs',
  'Çaglar Söyüncü',
  'Wes Morgan',
  'Jamie Vardy',
  'Kelechi Iheanacho',
  'Kasper Schmeichel',
  'Eldin Jakupovic',
  'Danny Ward',
  'James Maddison',
  'Harvey Barnes',
  'Rachid Ghezzal',
  'Marc Albrighton',
  'Demarai Gray',
  'Wilfred Ndidi',
  'Matty James',
  'Daniel Amartey',
  'Nampalys Mendy',
  'Hamza Choudhury',
  'Ayoze Pérez',
  'Ryan Bennett',
  'James Justin',
  'Youri Tielemans',
  'Andy King',
  'Dennis Praet',
  'Filip Benkovic',
  'Luke Thomas',
  'George Hirst',
  'Darnell Johnson',
  'Andrew Robertson',
  'Trent Alexander-Arnold',
  'Virgil van Dijk',
  'Joseph Gomez',
  'Joel Matip',
  'Dejan Lovren',
  'Roberto Firmino',
  'Divock Origi',
  'Alisson Ramses Becker',
  'Simon Mignolet',
  'Mohamed Salah',
  'Sadio Mané',
  'Alex Oxlade-Chamberlain',
  'Xherdan Shaqiri',
  'Adam Lallana',
  'Naby Keita',
  'Fabio Henrique Tavares',
  'Jordan Henderson',
  'Georginio Wijnaldum',
  'James Milner',
  'Rafael Camacho',
  'Rhian Brewster',
  'Adrián San Miguel del Castillo',
  'Andy Lonergan',
  'Caoimhin Kelleher',
  'Harvey Elliott',
  'Curtis Jones',
  'Neco Williams',
  'Nathaniel Phillips',
  'Takumi Minamino',
  'Ki-Jana Hoever',
  'Aymeric Laporte',
  'Kyle Walker',
  'Benjamin Mendy',
  'Oleksandr Zinchenko',
  'John Stones',
  'Nicolás Otamendi',
  'Danilo Luiz da Silva',
  'Sergio Agüero',
  'Gabriel Fernando de Jesus',
  'Ederson Santana de Moraes',
  'Claudio Bravo',
  'Raheem Sterling',
  'Kevin De Bruyne',
  'Leroy Sané',
  'Riyad Mahrez',
  'Bernardo Mota Veiga de Carvalho e Silva',
  'David Silva',
  'Phil Foden',
  'Fernando Luiz Rosa',
  'Ilkay Gündogan',
  'José Ángel Esmorís Tasende',
  'Rodrigo Hernandez',
  'João Pedro Cavaco Cancelo',
  'Eric Garcia',
  'Scott Carson',
  'Taylor Harwood-Bellis',
  'Cole Palmer',
  'Tommy Doyle',
  'Aaron Wan-Bissaka',
  'Harry Maguire',
  'Chris Smalling',
  'Ashley Young',
  'Victor Lindelöf',
  'Luke Shaw',
  'Marcos Rojo',
  'Eric Bailly',
  'José Diogo Dalot Teixeira',
  'Matteo Darmian',
  'Phil Jones',
  'Romelu Lukaku',
  'Marcus Rashford',
  'Mason Greenwood',
  'David de Gea',
  'Sergio Romero',
  'Lee Grant',
  'Paul Pogba',
  'Anthony Martial',
  'Alexis Sánchez',
  'Jesse Lingard',
  'Juan Mata',
  'Daniel James',
  'Frederico Rodrigues de Paula Santos',
  'Angel Gomes',
  'Andreas Pereira',
  'Nemanja Matic',
  'Scott McTominay',
  'Tahith Chong',
  'James Garner',
  'Axel Tuanzebe',
  'Brandon Williams',
  'Bruno Miguel Borges Fernandes',
  'Odion Ighalo',
  'Timothy Fosu-Mensah',
  'Matt Ritchie',
  'Fabian Schär',
  'DeAndre Yedlin',
  'Federico Fernández',
  'Paul Dummett',
  'Javier Manquillo',
  'Florian Lejeune',
  'Jamaal Lascelles',
  'Ciaran Clark',
  'Yoshinori Muto',
  'Jose Luis Mato Sanmartín',
  'Martin Dubravka',
  'Karl Darlow',
  'Frederick Woodman',
  'Miguel Almirón',
  'Christian Atsu',
  'Sung-yueng Ki',
  'Jonjo Shelvey',
  'Sean Longstaff',
  'Isaac Hayden',
  'Danny Rose',
  'Joelinton Cássio Apolinário de Lira',
  'Jack Colback',
  'Jacob Murphy',
  'Jetro Willems',
  'Rolando Aarons',
  'Allan Saint-Maximin',
  'Emil Krafth',
  'Andy Carroll',
  'Matthew Longstaff',
  'Dwight Gayle',
  'Robert Elliot',
  'Thomas Allan',
  'Kelland Watts',
  'Liam Gibson',
  'Nabil Bentaleb',
  'Valentino Lazaro',
  'Jack Young',
  'Timm Klose',
  'Ben Godfrey',
  'Maximillian Aarons',
  'Christoph Zimmermann',
  'Jamal Lewis',
  'Grant Hanley',
  'Teemu Pukki',
  'Dennis Srbeny',
  'Tim Krul',
  'Michael McGovern',
  'Mario Vrancic',
  'Emiliano Buendía',
  'Onel Hernández',
  'Marco Stiepermann',
  'Kenny McLean',
  'Todd Cantwell',
  'Moritz Leitner',
  'Alexander Tettey',
  'Tom Trybull',
  'Josip Drmic',
  'Patrick Roberts',
  'Ralf Fahrmann',
  'Sam Byram',
  'Philip Heise',
  'Ibrahim Amadou',
  'Adam Idah',
  'Archie Mair',
  'Akin Famewo',
  'Ondrej Duda',
  'Lukas Rupp',
  'Josh Martin',
  'Jordan Thomas',
  'Daniel Adshead',
  'Lys Mousset',
  'Enda Stevens',
  'Kieron Freeman',
  "Jack O'Connell",
  'George Baldock',
  'John Egan',
  'Richard Stearman',
  'John Lundstram',
  'Billy Sharp',
  'Jake Eastwood',
  'Mark Duffy',
  'John Fleck',
  'Oliver Norwood',
  'David McGoldrick',
  'Chris Basham',
  'Simon Moore',
  'Luke Freeman',
  'Phil Jagielka',
  'Callum Robinson',
  'Ravel Morrison',
  'Dean Henderson',
  'Ben Osborn',
  'Oliver McBurnie',
  'Michael Verrips',
  'Muhamed Bešić',
  'Leon Clarke',
  'Jack Rodwell',
  'Jack Robinson',
  'Sander Berge',
  'Richairo Zivkovic',
  'Panagiotis Retsos',
  'Jannik Vestergaard',
  'Ryan Bertrand',
  'Yan Valery',
  'Jan Bednarek',
  'Jack Stephens',
  'Maya Yoshida',
  'Kayne Ramsay',
  'Charlie Austin',
  'Danny Ings',
  'Michael Obafemi',
  'Shane Long',
  'Sam Gallagher',
  'Fraser Forster',
  'Alex McCarthy',
  'Angus Gunn',
  'Nathan Redmond',
  'James Ward-Prowse',
  'Mohamed Elyounoussi',
  'Stuart Armstrong',
  'Mario Lemina',
  'Pierre-Emile Højbjerg',
  'Tyreke Johnson',
  'Callum Slattery',
  'Oriol Romeu Vidal',
  'Kyle Walker-Peters',
  'Che Adams',
  'Moussa Djenepo',
  'Wesley Hoedt',
  'Sofiane Boufal',
  'Kevin Danso',
  "Daniel N'Lundulu",
  'Harry Lewis',
  'William Smallbone',
  'Nathan Tella',
  'Jake Vokins',
  'Alexandre Jankewitz',
  'Will Ferry',
  'Jacob Maddox',
  'Ben Davies',
  'Jan Vertonghen',
  'Toby Alderweireld',
  'Davinson Sánchez',
  'Kieran Trippier',
  'Juan Foyth',
  'Serge Aurier',
  'Harry Kane',
  'Vincent Janssen',
  'Hugo Lloris',
  'Paulo Gazzaniga',
  'Heung-Min Son',
  'Christian Eriksen',
  'Bamidele Alli',
  'Lucas Rodrigues Moura da Silva',
  'Erik Lamela',
  'Harry Winks',
  'Victor Wanyama',
  'Moussa Sissoko',
  'Eric Dier',
  'Luke Amos',
  'Oliver Skipp',
  'Tanguy Ndombele',
  'Troy Parrott',
  'Giovani Lo Celso',
  'Ryan Sessegnon',
  'Georges-Kévin Nkoudou',
  'Alfie Whiteman',
  'Michel Vorm',
  'Brandon Austin',
  'Japhet Tanganga',
  'Gedson Carvalho Fernandes',
  'Steven Bergwijn',
  'Harvey White',
  'Dennis Cirkin',
  'José Holebas',
  'Christian Kabasele',
  'Adam Masina',
  'Adrian Mariappa',
  'Francisco Femenía Far',
  'Craig Cathcart',
  'Daryl Janmaat',
  'Marc Navarro',
  'Sebastian Prödl',
  'Troy Deeney',
  'Gerard Deulofeu',
  'Andre Gray',
  'Isaac Success Ajayi',
  'Ben Foster',
  'Pontus Dahlberg',
  'Abdoulaye Doucouré',
  'Roberto Pereyra',
  'Will Hughes',
  'Tom Cleverley',
  'Ken Sema',
  'Etienne Capoue',
  'Nathaniel Chalobah',
  'Domingos Quina',
  'Craig Dawson',
  'Danny Welbeck',
  'Heurelho da Silva Gomes',
  'Ismaïla Sarr',
  'Dimitri Foulquier',
  'Ayotomiwa Dele-Bashiru',
  'João Pedro Junqueira de Jesus',
  'Ignacio Pussetto',
  'Adalberto Peñaranda',
  'Aaron Cresswell',
  'Angelo Ogbonna',
  'Arthur Masuaku',
  'Pablo Zabaleta',
  'Ryan Fredericks',
  'Issa Diop',
  'Fabián Balbuena',
  'Winston Reid',
  'Ben Johnson',
  'Marko Arnautovic',
  'Javier Hernández Balcázar',
  'Alexandre Nascimento Costa Silva',
  'Lukasz Fabianski',
  'Michail Antonio',
  'Felipe Anderson Pereira Gomes',
  'Manuel Lanzini',
  'Andriy Yarmolenko',
  'Robert Snodgrass',
  'Jack Wilshere',
  'Declan Rice',
  'Mark Noble',
  'Carlos Sánchez',
  'Grady Diangana',
  'Pablo Fornals',
  'Pedro Obiang',
  'Roberto Jimenez Gago',
  'Sébastien Haller',
  'Albian Ajeti',
  'David Martin',
  'Nathan Holland',
  'Joseph Anang',
  'Jeremy Ngakia',
  'Gonçalo Bento Soares Cardoso',
  'Bernardo Costa Da Rosa',
  'Darren Randolph',
  'Tomas Soucek',
  'Jarrod Bowen',
  'Alfie Lewis',
  'Conor Coventry',
  'Matt Doherty',
  'Jonathan Castro Otto',
  'Conor Coady',
  'Willy Boly',
  'Rúben Gonçalo Silva Nascimento Vinagre',
  'Romain Saïss',
  'Max Kilman',
  'Raúl Jiménez',
  'Diogo Jota',
  'Rui Pedro dos Santos Patrício',
  'John Ruddy',
  'Will Norris',
  'Rúben Diogo da Silva Neves',
  'João Filipe Iria Santos Moutinho',
  'Morgan Gibbs-White',
  'Adama Traoré',
  'Hélder Costa',
  'Ivan Cavaleiro',
  'Leander Dendoncker',
  'Jesús Vallejo Lázaro',
  'Patrick Cutrone',
  'Pedro Lomba Neto',
  'Bernard Ashley-Seal',
  'Taylor Perry',
  'Owen Otasowie',
  'Luke Cundle',
  'Oskar Buur',
  'Ryan Giles',
  'Bruno André Cavaco Jordao',
  'Daniel Castelo Podence',
  'Leonardo Campana'
]

export const pictures = [
  {
    name: 'Dublin City',
    image: '../../assets/PA201558.JPG',
    imageLow: '../../assets/PA201558-low.JPG',
    description: 'A view of the river Liffey'
  },
  {
    name: 'Sea Gull',
    image: '../../assets/PA201643.JPG',
    imageLow: '../../assets/PA201643-low.JPG',
    description: 'A sea gull in St. Stephens Green'
  },
  {
    name: 'Imperial Palace, Kyoto',
    image: '../../assets/kyoto-place.JPG',
    imageLow: '../../assets/kyoto-place-low.JPG',
    description: 'A gate in to the courtyard at the Imperial Palace in Kyoto'
  }
];

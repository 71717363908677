import { Component, OnInit } from '@angular/core';
import { pictures } from '../entries';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css']
})
export class ArticleListComponent implements OnInit {
  pictures = pictures;
  constructor() { }

  ngOnInit(): void {
  }

}

<div id="header" class="chart-selector">
  <h1>2019/20 Game Week Points</h1>
  <div class="control-wrapper">
    <label for="pname">Player: </label>
    <input type="text" id="pname" name="pname" list='playerlist'>
    <datalist id="playerlist"></datalist>
    <button (click)="updatePlayer()">Select Player</button>
    <button (click)="addPlayer()">Add Player</button>
  </div>
</div>
<div class="flex">
  <div class="flex-item">
    <div class="display-chart">
    <canvas baseChart responsive="true"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"></canvas>
    </div>
  </div>
</div>
<div class="spacer">
  <p>Use the buttons above the graph to change the players displayed.</p>
</div>

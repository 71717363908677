import { Component, OnInit, ViewChild } from '@angular/core';
import { gameweeks, players } from '../../assets/players/players'
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import {playerNames} from '../../assets/players/playernames';

@Component({
  selector: 'app-player-explorer',
  templateUrl: './player-explorer.component.html',
  styleUrls: ['./player-explorer.component.css']
})
export class PlayerExplorerComponent implements OnInit {
  // Player and gameweek data
  players = players;
  gameweeks = gameweeks;
  playernames = playerNames;

  // Helper funtion to create an array of means to match each game week.
  public meanValues (player) {
    var mean_array = []
    for (let i of gameweeks) {
      mean_array.push(player.mean)
    }
    return mean_array;
  };

  // Helper funtion to create an array of medaians to match each game week.
  public medianValues (player) {
    var median_array = []
    for (let i of gameweeks) {
      median_array.push(player.median)
    }
    return median_array;
  };

  // set the chart data, y-axis.
  public lineChartData: ChartDataSets[] = [
    { data: players[0].gameweeks, label: players[0].name },
    { data: this.medianValues(players[0]), label: "Median" },
    { data: this.meanValues(players[0]), label: "Mean" }
  ];

  public lineChartLabels: Label[] = gameweeks;
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // Line 1 red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(229,51,181,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // Line 2 Yellow
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: 'yellow',
      pointRadius: 0
    },
    { // Line 3 Blue
      backgroundColor: 'rgba(0,0,0, 0)',
      borderColor: 'blue',
      pointRadius: 0
    }
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() { }

  ngOnInit(): void {

    // Add the list of all player options.

    var plNamesHtml = '';

    for (let i in this.playernames) {
      plNamesHtml = plNamesHtml + "<option value='" + this.playernames[i] + "'>";
    }

    document.getElementById("playerlist").innerHTML = plNamesHtml;
  }

  public updatePlayer(): void {

    // Pull the player name.
    var player = (<HTMLInputElement>document.getElementById("pname")).value

    // first check if the player is real then update the label.
    for(let i=0; i<players.length; i++){
      if (players[i].name.toLowerCase() == player.toLowerCase()){

        // [0] is the gameweek data.
        this.lineChartData[0].label = players[i].name;
        this.lineChartData[0].data = players[i].gameweeks;

        // [1] is the median data
        this.lineChartData[1].data = this.medianValues(players[i]);

        // [2] is the mean data
        this.lineChartData[2].data = this.meanValues(players[i]);
      }
    }

    this.chart.update();
  }

  public addPlayer(): void {

    // Pull the player name.
    var player = (<HTMLInputElement>document.getElementById("pname")).value

    // first check if the player is real then update the label.
    for(let i=0; i<players.length; i++){
      if (players[i].name.toLowerCase() == player.toLowerCase()){

        // Remove player two data if it is on the chart.
        if(this.lineChartData.length > 3) {
          this.lineChartData.pop();
          this.lineChartData.pop();
          this.lineChartData.pop();
        }

        // Add a new player data to the array.
        this.lineChartData.push({ data: players[i].gameweeks, label: players[i].name });
        this.lineChartData.push({data: this.medianValues(players[i]), label: "Median" });
        this.lineChartData.push({ data: this.meanValues(players[i]), label: "Mean" });
      }
    }

    this.chart.update();
  }

  public randomize(): void {
    for (let i = 0; i < this.lineChartData.length; i++) {
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        this.lineChartData[i].data[j] = this.generateNumber(i);
      }
    }
    this.chart.update();
  }

  private generateNumber(i: number): number {
    return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }

  public pushOne(): void {
    this.lineChartData.forEach((x, i) => {
      const num = this.generateNumber(i);
      const data: number[] = x.data as number[];
      data.push(num);
    });
    this.lineChartLabels.push(`Label ${this.lineChartLabels.length}`);
  }

  public changeColor(): void {
    this.lineChartColors[2].borderColor = 'green';
    this.lineChartColors[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;
  }

  public changeLabel(): void {
    this.lineChartLabels[2] = ['1st Line', '2nd Line'];
  }
}
